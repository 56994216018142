import React from 'react';

const UserMultiValueLabel = ({ data }) => {
  return (
    <div className="flex items-center gap-2 px-1 py-1">
      <img alt={`${data?.name}'s Avatar`} src={data?.avatar} className="h-6 w-6 rounded-full" />
      <span className="text-sm font-medium">{data?.name}</span>
    </div>
  );
};

export default UserMultiValueLabel;
