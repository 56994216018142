import {Link, router} from '@inertiajs/react';
import {
  mdiArchive,
  mdiArchiveOutline,
  mdiCalendarClockOutline,
  mdiCheckCircle,
  mdiCheckCircleOutline,
  mdiClose,
  mdiContentDuplicate,
  mdiForumOutline,
  mdiInformationVariantCircle,
  mdiLoading,
  mdiPaperclip,
  mdiPaperclipPlus,
  mdiTextLong,
  mdiUpload,
} from '@mdi/js';
import {Icon} from '@mdi/react';
import {ConfirmationModal, Formik, Modal, trans, useInertiaForm} from '@webfox/webfox-ui';
import {File} from 'Components/DropzoneField';
import TinyMCEEditorField from 'Components/Fields/TinyMCEEditorField';
import TaskStatusIndicator from 'Components/Task/TaskStatusIndicator';
import CommentSection from 'Features/Tasks/Partials/CommentSection';
import SidebarButton from 'Features/Tasks/Partials/SidebarButton';
import SidebarPanel from 'Features/Tasks/Partials/SidebarPanel';
import TaskDateField from 'Features/Tasks/Partials/TaskDateField';
import TaskModalDropZone from 'Features/Tasks/Partials/TaskModalDropzone';
import {TaskTitle} from 'Features/Tasks/Partials/TaskTitle';
import UserAssignmentSection from 'Features/Tasks/Partials/UserAssignmentSection';
import React, {useEffect, useId, useMemo, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import axios from 'Support/axios';
import cls from 'Support/cls';
import useUser from 'Support/Hooks/useUser';
import {useOnClickOutside} from 'usehooks-ts';
import * as Yup from 'yup';
import route from 'ziggy-js';

const Section = ({title, icon, children}) => (
  <div className="flex flex-col gap-3 p-3">
    {(title || icon) && (
      <div className="flex items-center gap-2 text-slate-800">
        {typeof icon === 'string' ? <Icon path={icon} className="h-5 w-5"/> : icon}
        {title && <h3 className="text-lg font-semibold">{title}</h3>}
      </div>
    )}
    {children}
  </div>
);

const DuplicateOption = ({name, label, value, onChange, checked}) => {
  const id = useId();

  return (
    <div className="flex items-center gap-2">
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
        className="rounded-sm border-gray-300 text-primary focus:ring-primary"
      />
      <label htmlFor={id} className="text-sm font-medium text-slate-700">
        {label}
      </label>
    </div>
  );
};

const DuplicatePanel = ({task, onClose}) => {
  const ref = useRef(null);
  const [duplicating, setDuplicating] = useState(false);
  const [duplicateOptions, setDuplicateOptions] = useState({
    copy_files: true,
    copy_comments: true,
    copy_users: true,
  });

  useOnClickOutside(ref, () => {
    setDuplicating(false);
  });

  return (
    <div ref={ref} className="flex flex-col">
      <SidebarButton
        icon={mdiContentDuplicate}
        tooltip="Duplicate"
        onClick={() => {
          setDuplicating(!duplicating);
        }}
      />
      <div className={cls('duration-100', duplicating && 'mt-1')}>
        <SidebarPanel open={duplicating} onClose={() => setDuplicating(false)}>
          <div className="flex flex-col gap-2">
            {!!(task?.files?.length || task?.comments?.length || task?.users?.length) && (
              <>
                <span className="text-xs font-bold text-slate-700">Keep...</span>
                <div className="flex flex-col gap-1 pr-5">
                  {!!task?.files?.length && (
                    <DuplicateOption
                      label={`Attachments (${task.files.length})`}
                      name="copy_files"
                      value={true}
                      checked={duplicateOptions.copy_files}
                      onChange={({target: {checked}}) => setDuplicateOptions((prev) => ({...prev, copy_files: checked}))}
                    />
                  )}
                  {!!task?.comments?.length && (
                    <DuplicateOption
                      label={`Comments (${task.comments.length})`}
                      name="copy_comments"
                      value={true}
                      checked={duplicateOptions.copy_comments}
                      onChange={({target: {checked}}) => setDuplicateOptions((prev) => ({...prev, copy_comments: checked}))}
                    />
                  )}
                  {!!task?.users?.length && (
                    <DuplicateOption
                      label={`Users (${task.users.length})`}
                      name="copy_users"
                      value={true}
                      checked={duplicateOptions.copy_users}
                      onChange={({target: {checked}}) => setDuplicateOptions((prev) => ({...prev, copy_users: checked}))}
                    />
                  )}
                </div>
              </>
            )}
            <Link
              as="button"
              href={task?.id ? route('tasks.duplicate', task.id) : '#'}
              type="button"
              method="post"
              className="rounded bg-slate-50 px-3 py-2 text-sm font-medium hover:bg-slate-100"
              data={duplicateOptions}
              onSuccess={() => {
                onClose();
              }}
            >
              Duplicate Task
            </Link>
          </div>
        </SidebarPanel>
      </div>
    </div>
  );
};
const EditTaskModal = ({open, onClose, task}) => {
  const [deleting, setDeleting] = useState(false);
  const currentUser = useUser();
  const acknowledged = useMemo(() => task?.users.find((user) => user.id === currentUser.id)?.pivot?.data?.acknowledged ?? false, [task]);
  const [acknowledgedModalOpen, setAcknowledgedModalOpen] = useState(false);
  const [acknowledgedLoading, setAcknowledgedLoading] = useState(false);
  const dropzoneRef = useRef(null);
  const statusRef = useRef();
  const [statuses, setStatuses] = useState(null);

  useEffect(() => {
    axios.get(route('data-statuses.task')).then(({data}) => {
      setStatuses(data);
    });
  }, []);


  const formikProps = useInertiaForm({
    defaultValues: {
      title: task?.title ?? '',
      content: task?.content ?? '',
      due_at: task?.due_at ?? '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(trans('validation.required', {attribute: 'Title'}, 'Please provide a title')),
    }),
    submit: ({put}) => !!task && put(route('tasks.update', task.id)),
  });

  return (
    <Modal onClose={onClose} open={open} size="3xl">
      <Modal.Content withoutPadding wrapperClassName="relative bg-white rounded-lg overflow-visible">
        <TaskModalDropZone ref={dropzoneRef} task={task}>
          {({uploading}) => (
            <div className="flex flex-col p-5">
              <Formik {...formikProps}>
                {({submitForm}) => (
                  <div className="flex min-w-0 gap-10">
                    <div className="flex min-w-0 grow flex-col">
                      <div className="ml-3 flex flex-col gap-2">
                        <TaskTitle name="title" className="text-2xl font-semibold" onBlur={() => submitForm()}/>
                        <div
                          onClick={() => {
                            if (statusRef.current) {
                              statusRef.current.click();
                            }
                          }}
                          className={cls('flex items-center gap-2 self-start rounded-full p-1 hover:cursor-pointer', task?.status?.meta?.className || 'bg-slate-200')}
                        >
                          <TaskStatusIndicator task={task} statuses={statuses ?? []} ref={statusRef}/>
                          <span className="pr-2 text-sm font-medium">{task?.status?.label}</span>
                        </div>
                      </div>
                      <div className="relative mt-5 flex flex-col gap-3 outline-0">
                        <Section title="Assigned">
                          <UserAssignmentSection task={task}/>
                        </Section>
                        <Section title="Due Date" icon={mdiCalendarClockOutline}>
                          <TaskDateField name="due_at"/>
                        </Section>
                        <Section title="Description" icon={mdiTextLong}>
                          <TinyMCEEditorField
                            className="inline-content cursor-text"
                            inline={true}
                            placeHolderValue="Add more detail..."
                            contentName="content"
                            onBlur={() => {
                              submitForm();
                            }}
                          />
                        </Section>
                        <Section title="Attachments" icon={mdiPaperclip}>
                          <div className="flex flex-wrap gap-4">
                            {!!task?.files &&
                              task?.files?.map?.((file, index) => (
                                <File
                                  key={index}
                                  file={file}
                                  onRemove={() => {
                                    router.post(route('tasks.update-files', task?.id), {
                                      files: task?.files?.filter((f) => f !== file),
                                    });
                                  }}
                                />
                              ))}
                          </div>
                          {uploading && (
                            <div
                              className="flex items-center justify-between gap-2 rounded-lg border border-slate-200 bg-slate-100 px-3 py-2 text-secondary">
                              <div className="flex items-center gap-2">
                                <Icon path={mdiUpload} className="h-4 w-4"/>
                                <div>Uploading Files...</div>
                              </div>
                              <Icon path={mdiLoading} className="h-6 w-6 animate-spin"/>
                            </div>
                          )}
                          {!!task?.files?.length && (
                            <div className="flex w-full items-center gap-1 text-xs text-slate-500">
                              <Icon path={mdiInformationVariantCircle} className="h-4 text-blue-400"/>
                              Click files to download
                            </div>
                          )}
                          <button
                            onClick={() => dropzoneRef?.current?.open()}
                            className="flex items-center gap-3 self-start rounded-xl bg-slate-100 px-3 py-2 font-medium duration-100 hover:bg-slate-200"
                          >
                            <Icon path={mdiPaperclipPlus} className="h-4 w-4"/>
                            Add Attachment
                          </button>
                        </Section>
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <SidebarButton icon={mdiClose} tooltip="Close" onClick={onClose}/>
                      {!task?.deleted_at &&
                      <DuplicatePanel task={task} onClose={onClose}/>
                      }
                      <SidebarButton
                        icon={task?.deleted_at ? mdiArchive : mdiArchiveOutline}
                        tooltip={task?.deleted_at ? 'Restore' : 'Archive'}
                        onClick={() => {
                          if (!task?.can_complete) {
                            toast.warning('All assigned users must acknowledge the task before it can be archived', {toastId: task.id});
                            return;
                          }
                          setDeleting(true);
                        }}
                      />
                      {
                        task?.can_acknowledge &&
                        (acknowledgedLoading ?
                          <SidebarButton
                            type="button"
                            disabled={true}
                            icon={mdiLoading}
                            iconClasses="animate-spin"
                          >
                          </SidebarButton>
                          : <SidebarButton
                            type="button"
                            icon={acknowledged ? mdiCheckCircle : mdiCheckCircleOutline}
                            tooltip={acknowledged ? 'Repeal' : 'Acknowledge'}
                            onClick={() => {
                              setAcknowledgedLoading(true);
                              if (acknowledged) {
                                setAcknowledgedModalOpen(true);
                              } else {
                                router.put(route('tasks.acknowledge', task.id),
                                  {acknowledge: true},
                                  {
                                    onSuccess: () => {
                                      setAcknowledgedLoading(false);
                                    },
                                  },
                                );
                              }
                            }}
                          />)
                      }
                    </div>
                  </div>
                )}
              </Formik>
              <Section title="Comments" icon={mdiForumOutline}>
                <CommentSection task={task}/>
              </Section>
            </div>
          )}
        </TaskModalDropZone>
        <ConfirmationModal
          onCancel={() => setDeleting(false)}
          onConfirm={() => {
            router.delete(route('tasks.archive', task.id), {
              onSuccess: () => {
                setDeleting(false);
                onClose();
              },
            });
          }}
          open={deleting}
          title={task?.deleted_at ? 'Restore Task' : 'Archive Task'}
        >
          <span>Are you sure you want to {task?.deleted_at ? 'restore' : 'archive'} this task?</span>
        </ConfirmationModal>
        <ConfirmationModal
          onCancel={() => {
            setAcknowledgedModalOpen(false);
          }}
          onConfirm={() => {
            router.put(route('tasks.acknowledge', task.id),
              {acknowledge: false},
              {
                onSuccess: () => {
                  setAcknowledgedModalOpen(false);
                  setAcknowledgedLoading(false);
                },
              });
          }}
          open={acknowledgedModalOpen}
          title={'Repeal Task'}
        >
          <span>Are you sure you want to repeal this task?</span>
          <span> This task will no longer be acknowledged by you</span>
        </ConfirmationModal>
      </Modal.Content>
    </Modal>
  );
};

export default EditTaskModal;
