import { Link } from '@inertiajs/react';
import { mdiArrowLeft, mdiLogout, mdiRefresh } from '@mdi/js';
import { PrimaryButton, SecondaryButton } from '@webfox/webfox-ui';
import React, { useEffect, useRef } from 'react';
import route from 'ziggy-js';

const PageError = ({ error }) => {
  const animationRef = useRef();

  useEffect(() => {
    const animation = animationRef.current.animate([{ transform: 'translateX(-120%)' }, { transform: 'translateX(120%)' }], {
      duration: 6000,
      easing: 'ease-in-out',
      iterations: Infinity,
    });

    return () => {
      animation.cancel();
    };
  }, []);

  return (
    <div className="grow flex flex-col h-full w-full justify-center items-center gap-2">
      <img alt="Kauwaka Logo" src="/logo.png" className="opacity-10 h-16" />
      <div className="w-52 h-6 top-1/2 left-1/2 border border-primary rounded-b-full overflow-hidden opacity-25">
        <div ref={animationRef} className="bg-primary h-full w-full blur-lg" />
      </div>
      <h1 className="text-4xl font-semibold text-center text-slate-300">Something went wrong...</h1>
      <div className="text-center text-slate-400">
        <div>Developers have been notified</div>
        <div>In the meantime, here's some things you can try:</div>
      </div>
      <div className="flex items-center gap-3 mt-5">
        <SecondaryButton iconStart={mdiArrowLeft} onClick={() => window.history.back()}>
          Go Back
        </SecondaryButton>
        <Link href={route('logout')}>
          <SecondaryButton iconStart={mdiLogout}>Logout</SecondaryButton>
        </Link>
        <PrimaryButton onClick={() => window.location.reload()} iconStart={mdiRefresh}>
          Reload
        </PrimaryButton>
      </div>
    </div>
  );
};

export default PageError;
