import { mdiArrowLeft } from '@mdi/js';
import { Icon } from '@mdi/react';
import { trans } from '@webfox/webfox-ui';
import cls from 'classnames';
import { PrimaryButton } from 'Components/Buttons/PrimaryButton';
import { format } from 'date-fns';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'Support/axios';
import route from 'ziggy-js';

window.Pusher = Pusher;

function parseLinks(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const html = text.replace(urlRegex, url => `<a style='color: rgb(0, 162, 153); ' href='${url}'>${url}</a>`);
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

const Message = ({ message, date, currentUser, messageBefore }) => {

  const isCurrentUser = message.user.id === currentUser.id;

  const isGrouped = messageBefore && (new Date(message.created_at) - new Date(messageBefore.created_at)) < 600000;

  const shouldShowHeader = messageBefore?.user?.id !== message.user.id || !isGrouped;

  return (
    <div className={cls('flex flex-col gap-2', shouldShowHeader ? 'pt-4' : 'pt-2', isCurrentUser ? 'items-end pr-2' : 'items-start')}>
      {shouldShowHeader && <div className='flex gap-5 items-center'>
        <div className={cls('flex gap-2 items-center', isCurrentUser && 'order-2')}>
          <img src={message.user.avatar} className='w-8 h-8 rounded-full' />
          <div className='text-gray-600 font-semibold text-xs'>
            {message.user.name}
          </div>
        </div>
        <div className='text-xs text-gray-400 font-medium text-center'>
          {date}
        </div>
      </div>}
      <div className='flex justify-end max-w-[80%]'>
        <div className='bg-white shadow p-2 rounded-lg text-sm text-gray-500 font-medium whitespace-pre-line break-all'>
          {parseLinks(message.body)}
        </div>
      </div>
    </div>

  );
};

const GroupHeader = ({ text }) => (
  <div className='flex w-full items-center gap-4'>
    <div className='text-gray-400 text-xs font-semibold'>
      {text}
    </div>
    <hr className='w-full ' />
  </div>
);


const Threads = ({ threads, setThread, unreadThreadIds }) =>
  (
    <div className='h-full -mr-5'>
      <div className='flex items-center justify-between gap-2 pb-5'>
        <h2 className='text-xl font-medium text-slate-800'>{trans('ui.side_bar.threads.title', {}, 'Chats')}</h2>
      </div>
      <div className='overflow-y-auto h-full'>
        <div className='flex flex-col mb-10'>
          {threads?.map((thread) => {
            const isUnread = unreadThreadIds?.includes(thread.id);
            const name = thread?.name?.split('/');
            return (
              <button
                key={thread.id}
                onClick={() => {
                  setThread(thread);
                }}
                className={cls('h-full text-left flex gap-2 hover:bg-primary/25 py-2 rounded-l-lg transition-all duration-200 px-2', isUnread && 'bg-gray-200')}>

                <div className='h-10 p-0.5 rounded-full bg-primary/25' />
                <div>
                  <div className='flex items-center w-60 truncate-ellipse justify-end [direction:rtl]'>
                    <div className='line-clamp-1 text-sm font-medium [direction:ltr]'>{name[name.length - 1]}</div>
                    {name.length > 1 && <div className='text-gray-400 font-light'>&nbsp;/&nbsp;</div>}
                    {name?.slice(0, -1).map((item, index) => (
                      <div key={index} className='line-clamp-1 text-sm text-gray-400 font-light'>{item}</div>
                    ))}
                  </div>
                  <div
                    className={cls('text-xs pt-1 truncate w-60', isUnread ? 'font-bold' : 'font-light')}>{thread?.latest_message?.body ?? 'No messages yet'}</div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );

const Messages = ({ thread, soketiConfig, currentUser, setUnreadThreadIds, setThread }) => {

  const [message, setMessage] = useState('');

  const chatRef = useRef();
  const [echo, setEcho] = useState(null);

  const [messages, setMessages] = useState();

  useEffect(() => {
    axios.get(route('messages', { thread: thread.id })).then(({ data }) => setMessages(data));
  }, [thread]);

  useEffect(() => {
    //IT WASNT SCROLLING SO I DID THIS, SACK ME DO IT YOU WONT
    setTimeout(() => {
      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }
    }, 100);
  }, [chatRef]);

  useEffect(() => {
    if (!echo) {
      setEcho(new Echo({
          broadcaster: 'pusher',
          wsHost: soketiConfig?.host,
          key: soketiConfig?.key,
          cluster: 'soketi',
          forceTLS: true,
          wsPort: soketiConfig?.port,
          wssPort: soketiConfig?.port,
          enabledTransports: ['ws', 'wss']
        })
      );
    } else {
      echo.channel(`thread.${thread.id}`).listen('MessageSent', (e) => {
        if (e?.thread?.id === thread?.id) {
          if (e.message.user.id !== currentUser.id) {
            setUnreadThreadIds((prev) => {
              if (!prev.includes(e.thread.id)) {
                return [...prev, e.thread.id];
              }
              return prev;

            });
          }
          setMessages((prev) => ({
            ...prev,
            today: prev?.today?.length ? [...prev.today, e.message] : [e.message]
          }));
          setTimeout(() => {
            if (chatRef.current) {
              chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }
          }, 100);
        }
      });
    }
  }, [echo, thread]);

  return (
    <div className='h-full -mr-5'>
      <div className='flex items-center justify-between gap-2 pb-5'>
        <div className='flex gap-2'>
          <button onClick={() => {
            setThread(null);
          }}>
            <Icon path={mdiArrowLeft} className='w-6' />
          </button>
          <h2 className='text-xl font-medium text-slate-800'>{thread?.name + ' Chat' ?? trans('ui.side_bar.chat.title', {}, 'Chat')}
          </h2>
        </div>
      </div>

      <div className='overflow-y-auto h-4/5' ref={chatRef}>
        <div className='flex flex-col justify-end pb-5'>
          {!!messages?.earlier && <div>
            <div className='pt-4'>
              {
                messages?.earlier?.map((message, index) => {
                  let date = <div>
                    {format(new Date(message.created_at), 'dd MMM yy')}
                    <br />
                    {format(new Date(message.created_at), 'hh:mm a')}
                  </div>;
                  const messageBefore = messages?.earlier[index - 1];
                  return (
                    <Message key={index} message={message} messageBefore={messageBefore} date={date} currentUser={currentUser} />
                  );
                })
              }
            </div>
          </div>}
          {!!messages?.yesterday && <div className='pt-5'>
            <GroupHeader text='Yesterday' />
            <div className='pt-4'>
              {
                messages?.yesterday?.map((message, index) => {
                  let date = format(new Date(message.created_at), 'hh:mm a');
                  const messageBefore = messages?.yesterday[index - 1];
                  return (
                    <Message key={index} message={message} date={date} messageBefore={messageBefore} currentUser={currentUser} />
                  );
                })
              }
            </div>
          </div>}
          <div className='pt-5'>
            <GroupHeader text='Today' />
            <div className='pt-4'>
              {
                messages?.today?.map((message, index) => {
                  let date = format(new Date(message.created_at), 'hh:mm a');
                  const messageBefore = messages?.today[index - 1];
                  return (
                    <Message key={index} message={message} messageBefore={messageBefore} date={date} currentUser={currentUser} />
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-end gap-2 pr-5'>
        <textarea className='w-full h-20 border border-gray-200 rounded-lg p-2 shadow-lg'
                  value={message}
                  onClick={(e) => {
                    setUnreadThreadIds((prev) => prev.filter((id) => id !== thread.id));
                    axios.post(route('messages.mark-read', { thread: thread.id }));
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.shiftKey) {
                      e.preventDefault();
                      if (message.length !== 0) {
                        axios.post(route('messages.store', { thread: thread.id }), { body: message });
                        setMessage('');
                      }
                    }
                  }}
                  onChange={({ target: { value } }) => setMessage(value)} />
        <PrimaryButton disabled={message.length === 0} onClick={() => {
          axios.post(route('messages.store', { thread: thread.id }), { body: message });
          setMessage('');
        }}>
          Send
        </PrimaryButton>
      </div>

    </div>
  );
};

export const Chat = ({ unreadThreadIds, setUnreadThreadIds, currentUser, thread: initialThread }) => {
  const [thread, setThread] = useState(null);
  const [threads, setThreads] = useState(null);
  const [soketiConfig, setSoketiConfig] = useState(null);
  const [echo, setEcho] = useState();

  useEffect(() => {
    if (!echo && soketiConfig) {
      setEcho(new Echo({
          broadcaster: 'pusher',
          wsHost: soketiConfig?.host,
          key: soketiConfig?.key,
          cluster: 'soketi',
          forceTLS: true,
          wsPort: soketiConfig?.port,
          wssPort: soketiConfig?.port,
          enabledTransports: ['ws', 'wss']
        })
      );
    } else if (echo) {
      echo.channel(`user.${currentUser?.id}`).listen('MessageSent', (e) => {
        if (e.message.user.id !== currentUser.id) {
          setUnreadThreadIds((prev) => {
            if (!prev.includes(e.thread.id)) {
              return [...prev, e.thread.id];
            }
            return prev;

          });
        }
        setThreads((prev) => prev.map((thread) => {
          if (thread.id === e.thread.id) {
            return {
              ...thread,
              latest_message: e.message
            };
          }
          return thread;
        }));
      });
    }

    return () => {
      if (echo) {
        echo.leaveChannel(`user.${currentUser?.id}`);
        setEcho(null);
      }
    };

  }, [echo, soketiConfig]);

  useEffect(() => {
    if (initialThread && !thread && threads) {
      setThread(threads.find((thread) => thread?.id === initialThread?.id));
    }
  }, [initialThread, threads]);


  useEffect(() => {
    if (!threads) {
      axios.get(route('threads')).then(({ data }) => {
        setThreads(data.data);
      });
    }

    if (!soketiConfig) {
      axios.get(route('soketi-config')).then(({ data }) => {
        setSoketiConfig(data);
      });
    }

  }, []);

  if (thread && soketiConfig) {
    return <Messages setThread={setThread} thread={thread} setUnreadThreadIds={setUnreadThreadIds} soketiConfig={soketiConfig}
                     currentUser={currentUser} />;
  }

  return <Threads threads={threads} setThread={setThread} unreadThreadIds={unreadThreadIds} />;
};