import { Link } from '@inertiajs/react';
import { mdiLoading } from '@mdi/js';
import { Icon } from '@mdi/react';
import { TippyProps } from '@tippyjs/react';
import { Tooltip } from '@webfox/webfox-ui';
import cls from 'Support/cls';
import React, { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';

export interface PrimaryButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'title'> {
  button?: boolean;
  children: ReactNode;
  iconEnd?: string;
  iconStart?: string;
  isLoading?: boolean;
  title?: TippyProps['content'];
}

export const PrimaryButton = forwardRef<HTMLButtonElement, PrimaryButtonProps>(
  ({ children, className = '', disabled, iconEnd, iconStart, isLoading = false, title, ...extendedProps }: PrimaryButtonProps, ref) => {
    const baseClasses = cls(
      'inline-flex items-center justify-center gap-4 rounded-md border border-transparent py-2 text-sm font-medium text-white',
      'hover:bg-primary-highlight shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2',
    );

    let renderedElement;

    if (!className?.includes('h-')) {
      className = cls(className, 'h-11');
    }

    if (!className?.includes('w-')) {
      className = cls(className, 'sm:w-auto');
    }

    if (!className?.includes('text-sm')) {
      className = cls(className, 'text-base');
    }

    if (!className?.includes('px-')) {
      className = cls(className, 'px-6');
    }

    if (!className?.includes('bg-')) {
      className = cls(className, `bg-primary`);
    }

    if (disabled) {
      className = cls(className, 'cursor-not-allowed bg-opacity-50');
    }

    if (React.isValidElement(children) && children.type === Link) {
      renderedElement = React.cloneElement(children, {
        ...extendedProps,
        ...children.props,
        disabled,
        className: cls(baseClasses, className),
      });
    } else {
      renderedElement = (
        <button className={cls(baseClasses, className, iconStart && 'pl-5', iconEnd && 'pr-5')} disabled={disabled} {...extendedProps} ref={ref}>
          {iconStart && <Icon path={iconStart} size={0.8} />}
          {children}
          {iconEnd && <Icon path={iconEnd} size={0.8} />}
        </button>
      );
    }

    if (isLoading) {
      renderedElement = (
        <>
          <Icon path={mdiLoading} size={0.8} className="mr-2 animate-spin" />
          {renderedElement}
        </>
      );
    }

    return title === undefined ? renderedElement : <Tooltip content={title}>{renderedElement}</Tooltip>;
  },
);
