import { Transition } from '@headlessui/react';
import { mdiChevronDown } from '@mdi/js';
import { Icon } from '@mdi/react';
import cls from 'Support/cls';
import React, { useState } from 'react';
import useUser from 'Support/Hooks/useUser';

const lessons = import.meta.glob('Lessons/*.(jsx|tsx)', {
  import: 'default',
  eager: true,
});

const Lesson = ({ lesson }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      <button
        type="button"
        onClick={() => setExpanded(!expanded)}
        className="flex items-center justify-between rounded px-2 py-2 text-left font-medium duration-100 hover:bg-slate-200"
      >
        <span>{lesson?.title || lesson?.name}</span>
        <Icon path={mdiChevronDown} className={cls('w-6 duration-300', expanded && 'rotate-180')} />
      </button>
      <Transition
        show={expanded}
        enter="transition duration-300 ease-in-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-300 ease-in-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="border-y py-2 text-sm"
      >
        {lesson()}
      </Transition>
    </div>
  );
};
const Help = () => {
  const user = useUser();

  return (
    <div>
      <h2 className="mb-5 text-xl font-medium text-slate-800">Help</h2>
      <div className="flex flex-col gap-2">
        {Object.entries(lessons ?? {})?.reduce((acc, [path, lesson]) => {
          if (!lesson.filter || lesson?.filter?.(user)) acc.push(<Lesson key={path} lesson={lesson} />);
          return acc;
        }, [])}
      </div>
    </div>
  );
};

export default Help;
