import { Menu } from '@headlessui/react';
import { Link } from '@inertiajs/react';
import { Icon } from '@mdi/react';
import cls from 'Support/cls';
import React from 'react';

const MenuItem = ({ children, href, icon = undefined, as = Link, ...itemProps }) => (
  <Menu.Item {...itemProps}>
    {({ active, disabled }) => {
      const ElementTag = disabled ? 'button' : as;

      const elementProps = {};
      if (typeof href !== 'undefined') {
        elementProps.href = href;
      }

      if (disabled) {
        delete elementProps.href;
        elementProps.disabled = true;
      }

      if (ElementTag === 'button') {
        elementProps.type = 'button';
      }

      return (
        <ElementTag
          {...elementProps}
          className={cls({
            'bg-gray-50 text-gray-900': active,
            'text-gray-900': !active && !disabled,
            'cursor-default text-gray-700': disabled,
            'focus:bg-gray-100 focus:text-gray-900 focus:outline-none': !disabled,
            'flex w-full items-center space-x-3 px-4 py-2 text-left text-sm leading-5': true,
          })}
        >
          {icon && <Icon path={icon} size={0.7} className="h-5 w-5 shrink-0 text-gray-700" />}
          <span className="truncate">{children}</span>
        </ElementTag>
      );
    }}
  </Menu.Item>
);

export default MenuItem;
