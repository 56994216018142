import { mdiSend } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Formik, trans, useInertiaForm } from '@webfox/webfox-ui';
import TinyMCEEditorField from 'Components/Fields/TinyMCEEditorField';
import Comment from 'Features/Tasks/Partials/Comment';
import React from 'react';
import useUser from 'Support/Hooks/useUser';
import * as Yup from 'yup';
import route from 'ziggy-js';

const CommentSection = ({ task }) => {
  const currentUser = useUser();

  const formikProps = useInertiaForm({
    defaultValues: {
      comment: '',
    },
    validationSchema: Yup.object().shape({
      comment: Yup.string().required(trans('validation.required', { attribute: 'Comment' }, 'Please provide a comment')),
    }),
    submit: ({ post, reset }) => {
      post(route('tasks.comment', task.id), {
        onSuccess() {
          reset();
        },
      });
    },
  });

  return (
    <div className="flex flex-col">
      <Formik {...formikProps}>
        {({ submitForm, resetForm, values }) => (
          <div className="flex gap-5">
            <img src={currentUser.avatar} className="h-10 w-10 rounded-full" alt={`${currentUser?.name}'s Avatar`} />
            <div className="flex grow flex-col gap-2">
              <TinyMCEEditorField
                className="no-outline relative grow cursor-text border-b px-3 py-2 focus-within:border-primary"
                inline={true}
                placeHolderValue="Add a comment..."
                contentName="comment"
                onBlur={() => {}}
              />
              <div className="flex items-center justify-end gap-2">
                <button
                  type="button"
                  onClick={() => resetForm()}
                  className="rounded-full bg-slate-100 px-4 py-2 text-sm font-semibold duration-75 enabled:hover:bg-slate-200 disabled:bg-slate-200 disabled:opacity-0"
                  disabled={!values?.comment}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => submitForm()}
                  className="flex items-center gap-2 rounded-full bg-primary px-4 py-2 text-sm font-semibold text-white duration-75 enabled:hover:bg-opacity-80 disabled:bg-slate-200"
                  disabled={!values?.comment}
                >
                  <Icon path={mdiSend} className="h-4 w-4" />
                  <div>Comment</div>
                </button>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <div className="space-y-6 py-2">
        {task?.comments.map((comment, index) => (
          <Comment key={index} comment={comment} />
        ))}
      </div>
    </div>
  );
};

export default CommentSection;
