import { router } from '@inertiajs/react';
import React, { forwardRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import cls from 'Support/cls';
import route from 'ziggy-js';

const TaskModalDropZone = forwardRef(({ children, task, ...props }, ref) => {
  const [uploading, setUploading] = useState(false);
  const onDrop = (acceptedFiles) => {
    const files = [...(task?.files ?? []), ...acceptedFiles?.map((file) => ({ file: file }))];

    setUploading(true);
    router.post(
      route('tasks.update-files', task.id),
      {
        files: files,
      },
      {
        onFinish() {
          setUploading(false);
        },
      },
    );
  };

  return (
    <Dropzone ref={ref} noClick noKeyboard preventDropOnDocument onDrop={onDrop} {...props}>
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
        <div {...getRootProps()}>
          {typeof children === 'function' ? children({ getInputProps, isDragAccept, isDragReject, uploading }) : children}
          <div
            className={cls(
              'pointer-events-none absolute inset-0 z-50 flex flex-col items-center justify-center rounded-lg border duration-100',
              isDragReject ? 'border-red-500 bg-red-200' : isDragAccept ? 'border-primary bg-primary/20' : 'border-gray-300',
            )}
          ></div>
          <input {...getInputProps()} />
        </div>
      )}
    </Dropzone>
  );
});

export default TaskModalDropZone;
