import LeftNav from 'Layouts/Partials/LeftNav';
import Sidebar from 'Components/SideBar/Sidebar';
import React from 'react';
import { usePage } from '@inertiajs/react';
import { ExpandedCollapsibles, useSidebarState } from 'Support/Hooks/useSidebarState';
import MobileNav from 'Layouts/Partials/MobileNav';
import ErrorBoundary from 'Components/ErrorBoundary';
import PageError from 'Layouts/Partials/PageError';
import { TaskModalProvider } from 'Support/Contexts/TaskModalContext';

const MainLayout = ({ accountMenu, navItems, largeLogoSrc, smallLogoSrc, children }) => {
  const [expanded, setExpanded] = useSidebarState();
  const page = usePage();

  return (
    <TaskModalProvider>
      <div className="flex h-screen overflow-hidden bg-secondary">
        <div className="hidden lg:contents">
          <LeftNav
            items={navItems}
            largeLogoSrc={largeLogoSrc}
            smallLogoSrc={smallLogoSrc}
            accountMenu={accountMenu}
            collapsed={expanded !== ExpandedCollapsibles.NAVIGATION}
            onChangeCollapsed={(val) => setExpanded(val ? ExpandedCollapsibles.NONE : ExpandedCollapsibles.NAVIGATION)}
          />
        </div>
        <div className="contents lg:hidden">
          <MobileNav accountMenu={accountMenu} items={navItems} />
        </div>
        <main className="overflow-overlay relative grow overflow-x-hidden bg-white text-slate-800 lg:rounded-l-2xl">
        <ErrorBoundary render={PageError}>{children}</ErrorBoundary>
      </main>
        <div className="hidden lg:contents">
          <Sidebar
            page={page}
            collapsed={expanded !== ExpandedCollapsibles.SIDEBAR}
            onChangeCollapsed={(val: boolean) => setExpanded(val ? ExpandedCollapsibles.NONE : ExpandedCollapsibles.SIDEBAR)}
          />
        </div>
      </div>
    </TaskModalProvider>
  );
};

export default MainLayout;
