import {Listbox, Transition} from '@headlessui/react';
import {router} from '@inertiajs/react';
import TaskStatusIcon from 'Components/Task/TaskStatusIcon';
import React, {forwardRef} from 'react';
import {toast} from 'react-toastify';
import cls from 'Support/cls';
import route from 'ziggy-js';

const TaskStatusIndicator = forwardRef(({task, statuses}, ref) => {
  const className = task?.status?.meta?.className;

  return (
    <Listbox
      value={task?.status?.value}
      onChange={(status) => {
        if (status === task?.status?.value) return;
        if (status === 'complete' && !task.can_complete) {
          toast.warning('All users assigned must acknowledge the task before it can be completed', {toastId: task.id});
          return;
        }
        router.patch(route('tasks.update-status', task.id), {
          status: status,
        });
      }}
      as="div"
      className="relative"
      disabled={!statuses?.length}
    >
      <Listbox.Button
        ref={ref}
        className={cls('h-7 w-7 rounded-full border-2 bg-white p-0.5 duration-300', className, className ? 'border-transparent' : 'border-slate-200')}
      >
        <TaskStatusIcon status={task?.status?.value}/>
      </Listbox.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <Listbox.Options
          className="absolute -left-2.5 -top-2.5 z-10 flex transform flex-col gap-1 rounded-xl border border-slate-200 bg-white p-1 shadow-lg">
          {statuses?.map((status) => (
            <Listbox.Option key={status?.value} value={status?.value} as={React.Fragment}>
              {({active, selected}) => (
                <div
                  className={cls(
                    'flex cursor-pointer items-center gap-2 whitespace-nowrap rounded-lg p-1 text-sm duration-100',
                    selected ? (active ? 'bg-primary/30' : 'bg-primary/20') : active && 'bg-slate-100',
                  )}
                >
                  <div className="rounded-full border border-white">
                    <div
                      className={cls(
                        'h-7 w-7 rounded-full border-2 bg-white p-0.5',
                        status?.meta?.className,
                        status?.meta?.className ? 'border-transparent' : 'border-slate-200',
                      )}
                    >
                      <TaskStatusIcon status={status?.value}/>
                    </div>
                  </div>
                  <span className="font-medium">{status?.label}</span>
                </div>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
});

export default TaskStatusIndicator;
