import { FormikContext } from 'formik';
import React, { useContext, useRef } from 'react';
import { mdiPencil } from '@mdi/js';
import { Icon } from '@mdi/react';

export const TaskTitle = ({ name, onBlur, className }) => {
  const { values, setFieldValue } = useContext(FormikContext);
  const lastTitle = useRef(values[name]);

  return (
    <h2 className={className}>
      <div className="group/title relative">
        <textarea
          onBlur={() => {
            if (values[name]?.trim() === '') {
              setFieldValue(name, lastTitle.current);
            }
            onBlur();
          }}
          value={values[name]}
          onChange={(e) => setFieldValue(name, e.target.value?.slice(0, 255))}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.target.blur();
            }
          }}
          onFocus={(e) => {
            lastTitle.current = e.target.value;
          }}
          placeholder="Title"
          className="group absolute inset-0 resize-none overflow-hidden border-0 bg-transparent p-0 text-2xl outline-0 focus:border-b focus:border-b-slate-300 focus:ring-0"
        />
        <div className="whitespace-pre-wrap break-words text-2xl">
          <span className="invisible">{values[name] || 'Title'}</span>
          <div className="relative inline-block h-0 w-0 align-text-top group-focus-within/title:hidden">
            <Icon
              path={mdiPencil}
              className="absolute left-1 h-[1em] scale-0 text-slate-300 transition-all duration-200 group-hover/title:scale-100"
            />
          </div>
        </div>
      </div>
    </h2>
  );
};
