import cls from 'Support/cls';
import BundledEditor from 'Components/TinyMCE/BundledEditor';
import { FormikContext } from 'formik';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

const TinyEditorField = ({
  contentName,
  menubar = false,
  className,
  imagesName,
  placeHolderValue = '',
  init,
  fullScreen,
  inline = false,
  ...props
}) => {
  const { values, initialValues, setFieldValue, isSubmitting } = useContext(FormikContext);
  const editorRef = useRef();

  const initialValue = useMemo(() => {
    if (!initialValues?.[contentName]) {
      return '';
    }

    const doc = new DOMParser().parseFromString(initialValues[contentName], 'text/html');
    for (let image of doc.images) {
      image.dataset.originalSrc = image.src;
    }
    return doc.body.innerHTML;
  }, [initialValues?.[contentName]]);

  const transformValue = (value) => {
    if (imagesName) {
      if (value) {
        const parsedDoc = new DOMParser().parseFromString(value, 'text/html');
        for (let image of parsedDoc.images) {
          image.src = 'media:' + image.dataset.mediaId;
          delete image.dataset.originalSrc;
        }
        value = parsedDoc.body.innerHTML;
      }
    }
    setFieldValue(contentName, value);
  };

  useEffect(() => {
    if (imagesName && editorRef.current) {
      const doc = editorRef.current.getDoc();
      const promises = [];
      const imageSrcMap = new Map();

      for (let image of doc.images) {
        if (image.dataset.mediaId && image.dataset.originalSrc === image.src) {
          imageSrcMap.set(image.src, image.dataset.mediaId);
          promises.push(
            Promise.resolve({
              ...values?.[imagesName]?.[image.dataset.mediaId],
              id: image.dataset.mediaId,
              previewUrl: image.src,
            }),
          );
          continue;
        }

        image.dataset.originalSrc = image.src;
        const mediaId = imageSrcMap.get(image.src);
        if (mediaId) {
          image.dataset.mediaId = mediaId;
          continue;
        }

        const uuid = crypto.randomUUID();
        imageSrcMap.set(image.src, uuid);
        image.dataset.mediaId = uuid;

        if (!image.src?.startsWith('blob:')) {
          promises.push(Promise.resolve({ id: image.dataset.mediaId, previewUrl: image.src, url: image.src }));
          continue;
        }

        promises.push(
          fetch(image.src)
            .then((response) => response.blob())
            .then((blob) => {
              return {
                id: image.dataset.mediaId,
                file: new File([blob], image.dataset.mediaId, { type: blob.type }),
                previewUrl: image.src,
              };
            }),
        );
      }
      Promise.all(promises).then((images) => {
        setFieldValue(
          imagesName,
          images.reduce((acc, image) => {
            acc[image.id] = image;
            return acc;
          }, {}),
        );
      });
    }
  }, [values?.[contentName]]);
  return (
    <div className={cls('tiny-field', className)}>
      <BundledEditor
        onInit={(_, editor) => (editorRef.current = editor)}
        value={values[contentName]}
        init={{
          inline: inline,
          menubar: menubar,
          plugins: ['image', 'table', 'preview', 'autoresize', 'fullscreen', 'lists', 'media'],
          toolbar:
            'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table ' +
            (fullScreen ? ' | fullscreen' : ''),
          placeholder: placeHolderValue,
          file_picker_types: 'image media',
          /* This is the file picker callback provided by TinyMCE */
          file_picker_callback: (cb) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            /*
              Note: In modern browsers input[type="file"] is functional without
              even adding it to the DOM, but that might not be the case in some older
              or quirky browsers like IE, so you might want to add it to the DOM
              just in case, and visually hide it. And do not forget do remove it
              once you do not need it anymore.
            */

            input.onchange = function () {
              const file = this.files[0];

              const reader = new FileReader();
              reader.onload = function () {
                /*
                  Note: Now we need to register the blob in TinyMCEs image blob
                  registry. In the next release this part hopefully won't be
                  necessary, as we are looking to handle it internally.
                */
                const id = 'blobid' + new Date().getTime();
                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                const base64 = reader.result.split(',')[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                /* call the callback and populate the Title field with the file name */
                cb(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
            };

            input.click();
          },

          ...init,
        }}
        onEditorChange={transformValue}
        {...props}
      />
    </div>
  );
};

export default TinyEditorField;
