import { Link, usePage } from '@inertiajs/react';
import route from 'ziggy-js';
import React, { forwardRef, useMemo } from 'react';
import { Icon } from '@mdi/react';
import useUser from 'Support/Hooks/useUser';
import cls from 'Support/cls';

type MobileNavItemProps = {
  item: {
    icon: string | JSX.Element;
    href: string;
    active?: boolean | ((page: ReturnType<typeof usePage>) => boolean) | string[] | string;
    title?: string;
    showMobile?: boolean;
  };
};

const isActive = (...names: string[]) => names.some((name) => route().current(name, []));

const MobileNavItem = forwardRef<HTMLAnchorElement, MobileNavItemProps>(({ item }, ref) => {
  const icon = typeof item.icon === 'string' ? <Icon path={item.icon} /> : item.icon;
  const active = typeof item.active === 'function' ? item.active(usePage()) : isActive(item.active);

  return (
    <Link ref={ref} href={item.href} className="relative">
      <div className={cls('box-content h-8 w-8 px-2 py-4 duration-100', active ? '-translate-y-1 text-primary' : 'text-secondary')}>{icon}</div>
      <div className={cls('absolute -inset-x-1 bottom-0 h-0 bg-primary duration-100', active && 'h-0.5')} />
    </Link>
  );
});

type MobileNavProps = {
  items: MobileNavItemProps['item'][];
  accountMenu?: JSX.Element;
};

const MobileNav = ({ items: propItems, accountMenu }: MobileNavProps) => {
  const user = useUser();

  const items = useMemo(() => propItems.filter((item) => !!item.showMobile), [propItems]);

  return (
    <nav className="fixed inset-x-0 bottom-0 isolate z-50">
      <div
        className="relative isolate flex w-full items-center justify-between bg-white px-3 text-secondary"
        style={{
          boxShadow: '0 -1px 25px rgba(0, 0, 0, 0.1)',
        }}
      >
        {items.map((item, i) => (
          <MobileNavItem key={i} item={item} />
        ))}
        {accountMenu || <img src={user?.avatar} className="h-10 w-10 rounded-full" alt="Avatar" />}
      </div>
    </nav>
  );
};

export default MobileNav;
