import {
  mdiAccount,
  mdiAccountGroup,
  mdiBriefcase,
  mdiBriefcaseOutline,
  mdiChartGantt,
  mdiCheckAll,
  mdiCheckDecagram,
  mdiCog,
  mdiHome,
  mdiLogout,
  mdiPhone,
  mdiTableEdit,
  mdiTranscribe,
} from '@mdi/js';
import { trans, trans_choice } from '@webfox/webfox-ui';
import route from 'ziggy-js';

export interface PageProps {
  component: string;
  props: {
    application: {
      [key: string]: unknown;
    };
    errors: {
      [key: string]: unknown;
    };
    filters: {
      [key: string]: unknown;
    };
    flash: [];
    [key: string]: unknown;
  };
  url: string;
  version: string;

  [key: string]: unknown;
}

type NavItem =
  | {
      title: string;
      href: string;
      icon: string;
      active?: string | ((props: PageProps) => boolean);
      showMobile?: boolean;
      filter?: () => boolean;
    }
  | {
      title: string;
      items: NavItem[];
      filter?: () => boolean;
    };

const isActive = (...names: string[]) => names.some((name) => route().current(name, []));

const recursiveFilter = (item) => {
  if (typeof item.filter === 'function') {
    return item.filter();
  }

  if ('items' in item) {
    item.items = item.items.filter(recursiveFilter);
  }

  return true;
};

export const navItems = (user: any): NavItem[] => {
  // const hasPermission = (...permissionNames) => user.permissions.some((permission) => permissionNames.includes(permission.name));
  const hasRole = (...roleNames) => user.roles.some((role) => roleNames.includes(role));

  return (
    [
      {
        title: 'Dashboard',
        href: route('dashboard'),
        icon: mdiHome,
        active: 'dashboard',
        showMobile: true,
        filter: () => hasRole('admin', 'finance'),
      },
      {
        title: 'Tasks',
        href: route('tasks.index'),
        icon: mdiCheckAll,
        active: 'tasks.*',
      },
      {
        title: 'Transcripts',
        href: route('translator'),
        icon: mdiTranscribe,
        active: 'translator',
        showMobile: true,
        filter: () => hasRole('translator'),
      },
      {
        title: 'Quality Assurance',
        href: route('qa'),
        icon: mdiCheckDecagram,
        active: 'qa',
        showMobile: true,
        filter: () => hasRole('QA'),
      },
      {
        title: trans_choice('entities.quote', 2, {}, 'Quotes'),
        href: route('quotes.index'),
        icon: mdiBriefcaseOutline,
        active: ({ props }) => {
          const job = props?.job as
            | {
                is_quote: boolean;
              }
            | undefined;

          return job ? job.is_quote : isActive('quotes.*');
        },
        showMobile: true,
        filter: () => hasRole('admin', 'finance'),
      },
      {
        title: trans_choice('entities.translation_job', 2, {}, 'Jobs'),
        href: route('translations.index'),
        icon: mdiBriefcase,
        active: ({ props }) => {
          const job = props?.job as
            | {
                is_quote: boolean;
              }
            | undefined;

          return job ? !job.is_quote : isActive('translations.*');
        },
        showMobile: true,
        filter: () => hasRole('admin'),
      },
      {
        title: trans_choice('entities.project', 2),
        href: route('projects.index'),
        icon: mdiChartGantt,
        active: 'projects.*',
        filter: () => hasRole('admin', 'finance', 'project_manager'),
      },
      {
        title: trans_choice('entities.client', 2, {}, 'Clients'),
        href: route('clients.index'),
        icon: mdiAccountGroup,
        active: 'clients.*',
        showMobile: true,
        filter: () => hasRole('admin'),
      },
      {
        title: trans_choice('entities.contact', 2, {}, 'Contacts'),
        href: route('contacts.index'),
        icon: mdiPhone,
        active: 'contacts.*',
        filter: () => hasRole('admin'),
      },
      {
        title: trans('ui.management', {}, 'Management'),
        filter: () => hasRole('admin'),
        items: [
          {
            title: trans_choice('entities.user', 2, {}, 'Users'),
            href: route('users.index'),
            icon: mdiAccount,
            active: 'users.*',
            filter: () => hasRole('admin'),
          },
          {
            title: trans_choice('entities.setting', 2, {}, 'Settings'),
            href: route('settings.index'),
            icon: mdiCog,
            active: 'settings.*',
            filter: () => hasRole('admin'),
          },
          {
            title: trans_choice('entities.phase_type', 2, {}, 'Phase Types'),
            href: route('phase-types.index'),
            icon: mdiTableEdit,
            active: 'phase-types.*',
            filter: () => hasRole('admin'),
          },
        ],
      },
    ] satisfies NavItem[]
  ).filter(recursiveFilter);
};

type AccountMenuItem = {
  title: string;
  href: string;
  icon: string;
};

export const accountMenuItems: AccountMenuItem[] = [
  {
    title: 'Log out',
    href: route('logout'),
    icon: mdiLogout,
  },
];
