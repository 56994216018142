import { Popover, Transition } from '@headlessui/react';
import { mdiMinus, mdiPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Tooltip } from '@webfox/webfox-ui';
import cls from 'Support/cls';
import User from 'Components/User/User';
import type { User as UserType } from './UsersCell';
import UserSearch from 'Components/User/UserSearch';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import Select from 'react-select';

type UserAssignButtonProps = {
  assignedUsers?: UserType[];
  onAssign: (user: UserType, role: { value: string } | null) => void;
  roles: Array<{ value: string; label: string; name: string }>;
  removing: boolean;
  onRemove: (user: UserType) => void;
  onlyUserRoles?: string[];
  warning?: boolean;
  placement?: 'left' | 'right';
};

const UserAssignButton = forwardRef(
  (
    { assignedUsers = [], onAssign, roles = [], removing, onRemove, onlyUserRoles, warning = false, placement = 'right' }: UserAssignButtonProps,
    ref,
  ) => {
    const initialUser = useRef<any>(null);
    const popoverButton = useRef<any>(null);
    const closePopover = useRef<any>(null);

    useImperativeHandle(
      ref,
      () => ({
        open: (user = null) => {
          initialUser.current = user;
          popoverButton.current?.click?.();
        },
        close: () => closePopover.current?.(),
      }),
      [],
    );

    const [{ isOver }, drop] = useDrop(
      () => ({
        accept: 'user',
        drop: onRemove,
        collect: (monitor) => ({
          isOver: monitor.isOver() && monitor.canDrop(),
        }),
        canDrop: () => removing,
      }),
      [removing],
    );

    const onChangeUser = (user, setUser, close) => {
      if (roles?.length === 1) {
        onAssign(user, roles[0]);
        close();
      } else if (roles?.length < 1) {
        onAssign(user, null);
        close();
      } else {
        setUser(user);
      }
    };

    const roleList = useMemo(() => {
      const list = roles?.map((role) => role?.label);
      const lastRole = list.pop();
      return list.length ? `${list.join(', ')} or ${lastRole}` : lastRole ?? '';
    }, [JSON.stringify(roles)]);

    return (
      <Popover className="relative">
        {({ close }) => {
          closePopover.current = close;

          return (
            <>
              <Tooltip placement={placement} content={`Assign ${roleList}`}>
                <Popover.Button ref={popoverButton} className="focus:ring-highlight block rounded-full ring-offset-2 focus:outline-none focus:ring-2">
                  <div
                    ref={drop}
                    className={cls(
                      'focus:ring-highlight box-border flex h-8 w-8 items-center justify-center rounded-full border-2 p-1 duration-300 focus:outline-none focus:ring-2',
                      removing
                        ? isOver
                          ? 'border-red-500 bg-red-200 text-red-500'
                          : 'border-red-400 bg-red-100 text-red-400'
                        : warning
                        ? 'border-yellow-500 bg-yellow-200 text-yellow-500 hover:border-primary hover:bg-slate-100 hover:text-primary'
                        : 'border-slate-300 bg-slate-100 text-slate-500 hover:border-primary hover:text-primary',
                    )}
                  >
                    <Icon path={mdiPlus} className={cls('transition-dimensions duration-300', removing ? 'h-0 w-0' : 'h-7 w-7')} />
                    <Icon path={mdiMinus} className={cls('transition-dimensions duration-300', removing ? 'h-7 w-7' : 'h-0 w-0')} />
                  </div>
                </Popover.Button>
              </Tooltip>
              <Transition
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-y-95"
                enterTo="opacity-100 scale-y-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-y-100"
                leaveTo="opacity-0 scale-y-95"
                className={cls(
                  'absolute -top-3 z-50 flex min-w-80 origin-top flex-col gap-2 rounded border border-primary bg-white p-2 shadow-lg',
                  placement === 'right' ? 'left-full -translate-x-full' : 'right-full translate-x-full',
                )}
              >
                <Popover.Panel>
                  {({ close }) => {
                    const [user, setUser] = useState(initialUser.current);

                    useEffect(() => {
                      initialUser.current = null;
                    }, []);

                    return (
                      <div className="flex flex-col gap-2">
                        {user ? (
                          <div className="p-2">
                            <User user={user} />
                          </div>
                        ) : (
                          <UserSearch
                            additionalParams={{ ...(!!onlyUserRoles && { roles: onlyUserRoles }) }}
                            assignedUsers={assignedUsers}
                            value={null}
                            onChange={(user) => onChangeUser(user, setUser, close)}
                            menuShouldBlockScroll
                            autoFocus
                          />
                        )}
                        {user && (
                          <Select
                            placeholder="Select Role..."
                            options={roles}
                            onChange={(role) => {
                              onAssign?.(user, role);
                              close();
                            }}
                            menuShouldBlockScroll
                            autoFocus
                          />
                        )}
                      </div>
                    );
                  }}
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    );
  },
);

export default UserAssignButton;
