import { useCallback, useMemo, useSyncExternalStore } from 'react';
import { initSidebarStateStore } from 'Support/initStateStores';

initSidebarStateStore();
const subscribeToState = (key) => (callback) => {
  const onChange = (changedKey, state) => {
    if (changedKey === key) {
      callback(state);
    }
  };

  window.sidebar.onSidebarStateChange.push(onChange);

  return () => {
    window.sidebar.onSidebarStateChange.splice(window.sidebar.onSidebarStateChange.indexOf(onChange), 1);
  };
};

const useSidebarStateStore = (key, initialValue = undefined) => {
  if (window.sidebar.state[key] === undefined) {
    window.sidebar.state[key] = initialValue;
  }

  const subscribe = useMemo(() => {
    return subscribeToState(key);
  }, [key]);

  const state = useSyncExternalStore(subscribe, () => window.sidebar.state[key]);

  const setState = useCallback(
    (value) => {
      if (typeof value === 'function') {
        value = value(window.sidebar.state[key]);
      }

      window.sidebar.setState(key, value);
    },
    [key],
  );

  return [state, setState];
};

export default useSidebarStateStore;
