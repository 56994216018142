import { mdiPencil } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { formattedDate } from 'Support/valueFormatters';

const TaskDateField = ({ name }) => {
  const { submitForm } = useFormikContext();
  const [editing, setEditing] = useState(false);
  const [{ value }, _, { setValue }] = useField(name);

  return (
    <div className="contents font-medium text-slate-600">
      {editing ? (
        <input
          type="date"
          value={value}
          onFocus={({ target }) => {
            setEditing(true);
            target?.showPicker?.();
          }}
          onChange={({ target: { value } }) => setValue(value)}
          onBlur={() => {
            submitForm();
            setEditing(false);
          }}
          autoFocus
          className="self-start border-0 p-0 text-base tracking-tight ring-0 focus:ring-0"
        />
      ) : (
        <button onClick={() => setEditing(true)} className="group/date-button self-start rounded-lg border-0">
          <span className="inline-block text-base">{formattedDate(value, 'dd/MM/yyyy') || 'Never'}</span>
          <Icon path={mdiPencil} className="inline-block h-4 w-4 scale-0 text-slate-300 duration-100 group-hover/date-button:scale-100" />
        </button>
      )}
    </div>
  );
};

export default TaskDateField;
