import { Link } from '@inertiajs/react';
import { mdiBellOff } from '@mdi/js';
import { Icon } from '@mdi/react';
import { trans } from '@webfox/webfox-ui';
import cls from 'Support/cls';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { formatShortDatetime } from 'Support/valueFormatters';

const Notifications = ({ notifications }) => {
  return (
    <div>
      <div className="flex items-center justify-between gap-2">
        <h2 className="text-xl font-medium text-slate-800">{trans('ui.side_bar.notifications.title', {}, 'Notifications')}</h2>
      </div>
      <motion.div className="mt-2 flex flex-col gap-1">
        <AnimatePresence mode="popLayout">
          {notifications?.length ? (
            notifications?.map((notification) => (
              <motion.div key={notification.id} initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 10 }} layout>
                <Link
                  href={notification?.data?.link ?? '#'}
                  data={{
                    notif_id: notification.id,
                  }}
                  className={cls(
                    'flex items-start gap-5 rounded-xl p-2 text-left duration-150',
                    !!notification?.read_at ? 'opacity-50 hover:bg-slate-200' : 'hover:bg-white hover:shadow-lg',
                  )}
                >
                  <div className="max-w-10 h-10 max-h-10 min-h-10 w-10 min-w-10 shrink-0 rounded-full bg-slate-200">
                    {notification?.data?.image && (
                      <img src={notification?.data?.image} alt="Notification Image" className="h-full w-full rounded-full" />
                    )}
                  </div>
                  <div className="mt-0.5 flex grow flex-col">
                    <div className="text-sm" dangerouslySetInnerHTML={{ __html: notification?.data?.html_title }}></div>
                    {notification?.data?.html_content && (
                      <div className="text-xs text-slate-700" dangerouslySetInnerHTML={{ __html: notification?.data?.html_content }}></div>
                    )}
                    <span
                      className="line-clamp-1 pt-0.5 text-slate-400"
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {formatShortDatetime(notification?.data?.created_at)}
                      {!!notification?.data?.from && <> - {notification?.data?.from}</>}
                    </span>
                  </div>
                </Link>
              </motion.div>
            ))
          ) : (
            <div className="mt-5 flex items-center justify-center gap-3 p-5 text-slate-300">
              <Icon path={mdiBellOff} className="w-6" />
              <span>No Notifications</span>
            </div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default Notifications;
