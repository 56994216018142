import { usePage } from '@inertiajs/react';

/**
 * @returns {App.User|undefined}
 */
export default () => {
  const {
    application: { user },
  } = usePage().props;

  const hasPermission = (...permissionNames) => user.permissions.some((permission) => permissionNames.includes(permission.name));
  const hasRole = (...roleNames) => user.roles.some((role) => roleNames.includes(role));

  return { ...user, hasPermission: hasPermission, hasRole: hasRole };
};
