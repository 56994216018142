export const initSidebarStateStore = () => {
  window.sidebar = window.sidebar ?? {
    onSidebarStateChange: [],
    state: {},
  };

  const setState = (key, value) => {
    if (window.sidebar.state[key] !== value) {
      window.sidebar.state[key] = value;

      window.sidebar.onSidebarStateChange?.forEach((callback) => callback(key, window.sidebar.state));
    }
  };

  window.sidebar.setState = window.sidebar?.setState ?? setState;
};

const initStateStores = () => {
  initSidebarStateStore();
};

export default initStateStores;
