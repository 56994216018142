import EditTaskModal from 'Features/Tasks/Modals/EditTaskModal';
import React, { createContext } from 'react';

export const TaskModalContext = createContext({
  setTask: (param) => {},
  task: null,
});

export const TaskModalProvider = ({ children }) => {
  const [task, setTask] = React.useState(null);

  return (
    <TaskModalContext.Provider value={{ task, setTask }}>
      {children}
      <EditTaskModal task={task} open={!!task} onClose={() => setTask(null)} />
    </TaskModalContext.Provider>
  );
};
