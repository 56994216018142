import React from 'react';
import { IconProps } from '@mdi/react/dist/IconProps';
import { Icon } from '@mdi/react';
import { mdiCheckBold, mdiProgressHelper } from '@mdi/js';

type TaskStatusIconProps = Omit<IconProps, 'path'> & {
  status: string;
};

const statusToIcon = {
  in_progress: mdiProgressHelper,
  complete: mdiCheckBold,
};

const TaskStatusIcon = ({ status, ...props }: TaskStatusIconProps) => {
  return <Icon path={statusToIcon[status] ?? ''} {...props} />;
};

export default TaskStatusIcon;
