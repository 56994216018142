import { mdiHammer } from '@mdi/js';
import { Icon } from '@mdi/react';
import { trans } from '@webfox/webfox-ui';

const UnderConstructionSection = () => (
  <div className="pointer-events-none relative flex select-none flex-col items-center gap-3 overflow-hidden rounded-xl border border-primary/50 bg-white px-4 py-3 text-center text-primary shadow-xl">
    <Icon path={mdiHammer} className="absolute -top-4 left-2 w-60 -translate-x-1/2 opacity-10" />
    <h2 className="text-xl font-bold">{trans('ui.under_construction.section.title', {}, 'This section is under construction')}</h2>
    <span className="text-sm">
      {trans('ui.under_construction.section.message', {}, 'We are working on the content for this section')}
      <br />
      {trans('ui.under_construction.section.cta', {}, 'Please check back later for updates.')}
    </span>
  </div>
);

export default UnderConstructionSection;
