import {mdiClose} from '@mdi/js';
import {Icon} from '@mdi/react';
import {Tooltip} from '@webfox/webfox-ui';
import {User} from 'Components/User/UsersCell';
import React, {useEffect} from 'react';
import {useDrag} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import UserDragItem from 'Support/DragItems/UserDragItem';

type UserAvatarDraggableProps = {
  editable?: boolean;
  user: User;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onRemove?: () => void;
  subtitle?: string;
  additionalData?: Record<string, unknown>;
  itemType?: string;
};

const UserAvatarDraggable = ({
  editable,
  user,
  onDragStart,
  onDragEnd,
  onRemove,
  subtitle,
  additionalData,
  itemType = 'user',
}: UserAvatarDraggableProps) => {
  const [{isDragging}, drag, preview] = useDrag(
    {
      type: itemType,
      item: UserDragItem(user, {
        onDrag: onDragStart,
        additionalData: {
          ...additionalData,
          draggable_type: 'avatar',
        },
      }),
      end: onDragEnd,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [user, onDragStart, onDragEnd, additionalData, itemType],
  );

  // Use blank drag preview image
  useEffect(() => {
    preview(getEmptyImage(), {captureDraggingState: true});
  }, [preview]);

  return (
    <Tooltip
      content={
        <div className="flex flex-col">
          <span>{user?.name || user?.email}</span>
          {subtitle && <span className="text-xs text-gray-500">{subtitle}</span>}
        </div>
      }
      disabled={!(user?.name || user?.email)}
      delay={[500, 0]}
    >
      <div
        ref={drag}
        className="user-avatar group/avatar flex h-8 items-center overflow-hidden rounded-full border-2 border-transparent bg-clip-content duration-300 hover:border-primary hover:bg-slate-50"
      >
        <div className="-m-1 block h-9 w-9 p-1 duration-100 group-hover/avatar:p-1.5">
          <img src={user?.avatar} alt={`${user?.name || user?.email}'s Avatar`} className="animate-scale-fade-in cursor-grab rounded-full"/>
        </div>
        {editable && (
          <button
            type="button"
            onClick={onRemove}
            disabled={!onRemove || isDragging}
            className="block h-full w-0 duration-300 enabled:group-hover/avatar:w-8 enabled:group-hover/avatar:px-1 enabled:group-hover/avatar:delay-1000"
          >
            <Icon path={mdiClose} className="box-content w-4 rounded-full p-1 hover:bg-red-100"/>
          </button>
        )}
      </div>
    </Tooltip>
  );
};

export default UserAvatarDraggable;
