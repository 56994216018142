import { Tab, Transition } from '@headlessui/react';
import { router } from '@inertiajs/react';
import { mdiBellOutline, mdiDockWindow, mdiHelpCircleOutline, mdiMessageAlertOutline, mdiMessageOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Tooltip } from '@webfox/webfox-ui';
import { Chat } from 'Components/SideBar/Chat';
import Notifications from 'Components/SideBar/Notifications';
import UnderConstructionSection from 'Components/UnderConstruction/UnderConstructionSection';
import { AnimatePresence, motion } from 'framer-motion';
import Help from 'Layouts/Partials/Help';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import cls from 'Support/cls';
import useDevMode from 'Support/Hooks/useDevMode';
import useSidebarStateStore from 'Support/Hooks/useSidebarStateStore';
import { useLocalStorage } from 'usehooks-ts';
import route from 'ziggy-js';

/**
 * Sidebar component
 *
 * Any used page data within the sidebar should be passed in as a prop
 * to ensure that the sidebar is kept in sync with the page when it is
 * popped out.
 *
 * Any states that should be synced with the popped out sidebar should
 * be stored in the sidebar state store. See `Support/Hooks/useSidebarStateStorage`
 */
const Sidebar = ({ collapsible = true, canPopout = true, page, collapsed: propCollapsed, onChangeCollapsed }) => {
  const [selectedTab, setSelectedTab] = useLocalStorage('sidebar-tab', 0);
  const [poppedOut, setPoppedOut] = useState(false);
  const popWindow = useRef();
  const tabListContainer = useRef();
  const tabPanelContainer = useRef();
  const [devMode] = useDevMode();


  //may eventually be used for displaying the number of unread messages
  const [unreadThreadIds, setUnreadThreadIds] = useSidebarStateStore('message-count', page?.props?.userUnreadThreadIds ?? []);

  const collapsed = collapsible && propCollapsed;

  const tabs = [];

  if (devMode) {
    tabs.push({
      label: <div className='relative'>
        <Icon path={mdiMessageOutline} className='w-6' />
        {unreadThreadIds?.length > 0 ?
          <div
            className='absolute text-blue-800 bg-blue-100 bg-opacity-80 text-xs h-4 w-4 -top-1 -right-1 rounded-full flex items-center justify-center'>
            {unreadThreadIds?.length}
          </div>
          : null}
      </div>,
      content:
        <Chat currentUser={page?.props?.application?.user}
              unreadThreadIds={unreadThreadIds}
              setUnreadThreadIds={setUnreadThreadIds}
              thread={page?.props?.thread}
        />
    });
  }

  tabs.push(
    {
      label: <Icon path={mdiMessageAlertOutline} className='w-6' />,
      // Disabled for now while update types are being figured out
      // content: <ActivityFeed page={page} />,
      content: <UnderConstructionSection />
    },
    {
      label: (
        <div className='relative'>
          <Icon path={mdiBellOutline} className='w-6' />
          {page?.props?.application?.user?.notifications?.some((notification) => !notification.read_at) && (
            <div className='absolute right-0 top-0 h-2 w-2 animate-scale-fade-in rounded-full border border-slate-200 bg-blue-500' />
          )}
        </div>
      ),
      content: <Notifications notifications={page?.props?.application?.user?.notifications} />
    },
    {
      label: <Icon path={mdiHelpCircleOutline} className='w-6' />,
      content: <Help page={page} />
    }
  );

  const onOpenPopout = () => {
    setPoppedOut(true);
    const windowWidth = tabListContainer.current?.offsetWidth + tabPanelContainer.current?.offsetWidth;

    popWindow.current = window.open(
      route('sidebar'),
      'sidebar',
      `width=${windowWidth},height=${window.innerHeight},top=${window.outerHeight - window.innerHeight},left=${
        window.innerWidth + (window.screenX ?? window.screenLeft) - windowWidth
      }popup=1,location=0,menubar=0,status=0,toolbar=0`
    );

    const onPopoutClose = () => {
      setPoppedOut(false);
      popWindow.current?.removeEventListener('beforeunload', onPopoutClose);
      popWindow.current?.removeEventListener('load', onLoad);
    };

    const onLoad = () => {
      popWindow.current.dispatchSidebarEvent = (event, data) => {
        switch (event) {
          case 'page-change':
            router.visit(data.url, data.visitOptions);
        }
      };

      popWindow.current.sidebar = window.sidebar;

      popWindow.current?.addEventListener('beforeunload', onPopoutClose);
    };

    popWindow.current?.addEventListener('load', onLoad);
  };

  useEffect(() => {
    const onBeforeUnload = () => {
      popWindow?.current?.close();
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  window.sidebar?.setState('page', page);

  return poppedOut ? null : (
    <aside className='relative overflow-hidden bg-slate-100 shrink-0'>
      <div className='relative flex h-full min-h-0'>
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.Panels ref={tabPanelContainer} className={cls('relative box-content w-96 grow duration-500', collapsed ? '-ml-96' : 'ml-0')}>
            <div className='flex h-full min-h-0 grow flex-col p-5'>
              {tabs.map((tab, index) => (
                <Tab.Panel as={Fragment} key={index}>
                  {({ selected }) => (
                    <Transition
                      appear
                      show={selected}
                      enter='transition-opacity duration-300 ease-in'
                      enterFrom='opacity-0'
                      enterTo='opacity-100 h-full'
                    >
                      {typeof tab?.content === 'function' ? tab.content({ selected }) : tab.content}
                    </Transition>
                  )}
                </Tab.Panel>
              ))}
            </div>
          </Tab.Panels>

          <div ref={tabListContainer} className='flex flex-col items-center justify-between bg-slate-200'>
            <Tab.List className='flex flex-col self-stretch'>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  onClick={(e) => {
                    if (collapsible) {
                      if (index === selectedTab) {
                        e.preventDefault();
                        onChangeCollapsed(!propCollapsed);
                      } else {
                        onChangeCollapsed(false);
                      }
                    }
                  }}
                  className='group/tab relative p-4 outline-none duration-150 hover:bg-slate-300 focus:outline-none'
                >
                  {({ selected }) => (
                    <>
                      <AnimatePresence>
                        {selected && !collapsed && (
                          <motion.div
                            layoutId='highlight'
                            className='absolute inset-0 z-0 bg-slate-100'
                            initial={{
                              opacity: 0
                            }}
                            animate={{
                              opacity: 1
                            }}
                            exit={{
                              opacity: 0
                            }}
                          />
                        )}
                      </AnimatePresence>
                      <div className={cls('relative z-10 duration-300', selected && !collapsed ? 'text-primary' : 'text-slate-600')}>
                        {typeof tab?.label === 'function' ? tab.label({ selected }) : tab.label}
                      </div>
                    </>
                  )}
                </Tab>
              ))}
            </Tab.List>
            {canPopout && (
              <Tooltip content='Open in Popout' placement='right'>
                <button type='button' onClick={onOpenPopout} className='m-2 rounded-full p-2 text-slate-700 duration-100 hover:bg-slate-300'>
                  <Icon path={mdiDockWindow} className='w-6' />
                </button>
              </Tooltip>
            )}
          </div>
        </Tab.Group>
      </div>
    </aside>
  );
};

export default Sidebar;
