import {router} from '@inertiajs/react';
import {mdiCheckCircle} from '@mdi/js';
import {Icon} from '@mdi/react';
import {Tooltip} from '@webfox/webfox-ui';
import UserAssignButton from 'Components/User/UserAssignButton';
import UserAvatarDraggable from 'Components/User/UserAvatarDraggable';
import React, {useState} from 'react';
import route from 'ziggy-js';

export const AcknowledgedTick = ({user, userCount}) => (
  (user?.pivot?.data?.acknowledged && userCount > 1) &&
  <Tooltip content="Acknowledged">
    <Icon path={mdiCheckCircle} className="h-4 absolute -top-1 -right-1 text-green-500 fill-white bg-white rounded-full"/>
  </Tooltip>
);
const UserAssignmentSection = ({task}) => {
  const [dragging, setDragging] = useState(false);

  const onRemove = (user) => {
    router.delete(
      route('tasks.users.unassign', {
        task: task.id,
        user: user.id,
      }),
      {
        preserveState: true,
        preserveScroll: true,
      },
    );
  };

  return (
    <div className="flex flex-wrap items-center gap-1">
      {!task?.deleted_at &&
        <UserAssignButton
          assignedUsers={task?.users ?? []}
          onAssign={(user) => {
            router.post(
              route('tasks.users.assign', task.id),
              {
                user_id: user?.id,
              },
              {
                preserveState: true,
                preserveScroll: true,
              },
            );
          }}
          placement="left"
          roles={[]}
          removing={dragging}
          onRemove={(user) => onRemove(user)}
        />
      }
      {task?.users?.map((user) => (
        <div className="relative" key={user?.id}>
          <UserAvatarDraggable
            editable
            user={user}
            onDragStart={() => setDragging(true)}
            onDragEnd={() => setDragging(false)}
            onRemove={!task?.deleted_at ? () => onRemove(user) : undefined}
            additionalData={{
              entityId: task?.id,
              entityType: 'task',
            }}
          />
          <AcknowledgedTick user={user} userCount={task?.users?.length}/>
        </div>
      ))}
    </div>
  );
};

export default UserAssignmentSection;
