import type { User as UserType } from 'Components/User/UsersCell';
import React, { forwardRef } from 'react';

export const User = forwardRef<HTMLDivElement, { user: UserType; hideRoles?: boolean }>(({ user, hideRoles = false }, ref) => (
  <div ref={ref} className="flex items-center gap-2">
    <img src={user.avatar} className="h-8 w-8 rounded-full" alt={`${user?.name}'s Avatar`} />
    <div className="flex flex-col">
      <span>{user.name || user?.email}</span>
      {!hideRoles && !!user?.roles?.length && (
        <div className="flex flex-wrap items-center gap-1 text-xs text-gray-500">
          {user?.roles?.map((role) => (
            <div key={role?.id} className="rounded bg-slate-100 px-1 py-0.5 font-medium">
              {role?.enum.label}
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
));
export default User;
