type DragItemOptions = {
  onDrag?: null | (() => void | null);
  additionalData?: object | null;
};

type DragItem = () => {
  additionalData?: object | null;
};

function BaseDragItem(item: object | null, { onDrag = null, additionalData }: DragItemOptions = {}): DragItem {
  return () => {
    onDrag?.();

    return {
      ...item,
      additionalData,
    };
  };
}

BaseDragItem.type = 'base';
export default BaseDragItem;
