import { mdiClose, mdiDownload, mdiFile, mdiInformationVariantCircle } from '@mdi/js';
import { Icon } from '@mdi/react';
import { FieldContext, formatNumber, trans } from '@webfox/webfox-ui';
import cls from 'Support/cls';
import { useField } from 'formik';
import React, { useContext } from 'react';
import Dropzone from 'react-dropzone';

export const File = ({ file, onRemove }) => {
  file = file?.file || file;
  const Component = file.original_url ? 'a' : 'div';
  const componentProps = file.original_url ? { href: file.download_url, target: '_blank', rel: 'noopener noreferrer' } : {};

  return (
    <div className="flex rounded-lg shadow-sm">
      {onRemove && (
        <button type="button" onClick={() => onRemove(file)} className="rounded-l-lg bg-red-300 px-1 text-red-700 duration-100 hover:bg-red-400">
          <Icon path={mdiClose} className="w-4" />
        </button>
      )}
      <Component
        className={cls('flex items-center gap-2 rounded-r-lg border border-l-0 bg-white pl-3 pr-5 ', file.original_url ? 'group cursor-pointer' : '')}
        {...componentProps}
      >
        <Icon
          path={mdiFile}
          className={cls('w-10 rounded-full bg-primary/20 p-2 text-primary', file.original_url ? 'group-hover:hidden group-focus:hidden' : '')}
        />
        {file.original_url ? (
          <Icon path={mdiDownload} className="hidden w-10 rounded-full bg-primary/20 p-2 text-primary group-hover:block group-focus:block" />
        ) : null}
        <div className="flex flex-col py-3">
          <div>{file?.name || file?.file?.name}</div>
          <div className="flex justify-between">
            <span className="text-sm text-gray-500">
              {formatNumber(file?.size || file?.file?.size, 0, {
                style: 'unit',
                unit: 'byte',
                unitDisplay: 'narrow',
                notation: 'compact',
              })}
            </span>
          </div>
        </div>
      </Component>
    </div>
  );
};

const DropzoneField = ({ multiple, disabled, ...props }) => {
  const { name } = useContext(FieldContext);
  const [{ value }, _, { setValue }] = useField(name);

  const onDrop = (acceptedFiles) => {
    if (!!multiple) {
      return setValue([...(value ?? []), ...acceptedFiles?.map((file) => ({ file: file }))]);
    }

    return setValue({ file: acceptedFiles?.[0] });
  };

  const onRemove = (file) => {
    if (!!multiple) {
      return setValue(value?.filter((f) => f?.file !== file));
    }

    return setValue(null);
  };

  return (
    <div className="flex flex-wrap items-center gap-2">
      {!!value &&
        (value?.map?.((file, index) => <File key={index} file={file} onRemove={onRemove} />) ?? (
          <File file={value} onRemove={!disabled && onRemove} />
        ))}
      {(!!multiple || !value) && !disabled && (
        <Dropzone multiple={!!multiple} {...props} onDrop={onDrop} preventDropOnDocument>
          {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
            <div
              {...getRootProps({
                className: cls(
                  'relative grow cursor-pointer rounded-lg border p-6 duration-100 hover:border-primary hover:bg-gray-200 focus:border-primary focus:outline-none',
                  isDragReject ? 'border-red-500 bg-red-200' : isDragAccept ? 'border-dashed border-primary bg-primary/20' : 'border-gray-300',
                ),
              })}
            >
              <input {...getInputProps()} />
              <p className={cls(isDragReject ? 'text-red-800' : isDragAccept ? 'animate-pulse text-gray-800 mix-blend-multiply' : 'text-gray-700')}>
                {isDragReject
                  ? trans('ui.fields.dropzone.invalid', 'File type not allowed')
                  : trans(
                      'ui.fields.dropzone.drop_message',
                      { browse: trans('ui.fields.dropzone.browse', {}, 'browse') },
                      'Drag and drop your files here or browse',
                    )}
              </p>
            </div>
          )}
        </Dropzone>
      )}
      {!!value?.length && (
        <div className="flex w-full items-center gap-1 text-xs text-slate-500">
          <Icon path={mdiInformationVariantCircle} className="h-4 text-blue-400" />
          Click files to download
        </div>
      )}
    </div>
  );
};

export default DropzoneField;
