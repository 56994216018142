import {mdiAccountCircle} from '@mdi/js';
import {Icon} from '@mdi/react';
import { formatLongDatetime, trans } from '@webfox/webfox-ui';
import React from 'react';

const Comment = ({ comment }) => (
  <div className="mx-2 flex gap-3">
    {
      comment.user?.avatar ?
        <img src={comment.user?.avatar} className="h-8 w-8 rounded-full" alt={`${comment.user?.name}'s Avatar`}/>
        :
        <div className="h-8 w-8 rounded-full bg-slate-300 flex items-center justify-center">
          <Icon path={mdiAccountCircle} className="h-6 w-6 text-slate-500" />
        </div>
    }
    <div className="flex flex-grow flex-col items-stretch gap-1">
      <div className="flex items-baseline justify-between gap-4">
        <p className="font-medium">{comment.user?.name ?? 'Deleted user'}</p>
        <p className="text-xs font-medium text-slate-400">{formatLongDatetime(comment.created_at, trans('ui.time.never'))}</p>
      </div>
      <div className="rounded-xl bg-slate-100 px-3 py-2" dangerouslySetInnerHTML={{ __html: comment.content }} />
    </div>
  </div>
);

export default Comment;
