import { useStateDebounced } from '@webfox/webfox-ui';
import UserMultiValueLabel from 'Components/User/UserMultiValueLabel';
import { UserOption } from 'Components/User/UserOption';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'Support/axios';
import route from 'ziggy-js';

export const UserSearch = ({ assignedUsers, additionalParams, ...props }) => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useStateDebounced('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (search) {
      setLoading(true);
      axios
        .get(route('users.search', { search, ...additionalParams }))
        .then(({ data }) => setUsers(data))
        .finally(() => setLoading(false));
    } else {
      setUsers([]);
    }
  }, [search, additionalParams]);

  return (
    <Select
      isLoading={loading}
      options={users}
      styles={
        props?.isMulti
          ? {}
          : {
              input: (provided) => ({
                ...provided,
                position: 'absolute',
              }),
              valueContainer: (provided) => ({
                ...provided,
                overflow: 'visible',
              }),
            }
      }
      components={{
        MultiValueLabel: UserMultiValueLabel,
        SingleValue: UserOption,
        Option: UserOption,
      }}
      placeholder="Search Users..."
      noOptionsMessage={() => (search ? 'No users found' : 'Start typing to search users')}
      getOptionLabel={(user) => user.name}
      getOptionValue={(user) => user.id}
      onInputChange={(value) => setSearch(value)}
      filterOption={({ data }) => !assignedUsers?.some((assignedUser) => assignedUser.id === data.id)}
      {...props}
    />
  );
};

export default UserSearch;
