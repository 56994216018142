import { CustomDragLayer } from 'Components/DragLayer/CustomDragLayer';
import React, { useSyncExternalStore } from 'react';

const onComponentRegister = [];
const registeredComponents = new Map();
export const registerDragComponent = (types, component = null) => {
  if (Array.isArray(types)) {
    types.forEach((type) => registerDragComponent(type, component));
  } else {
    registeredComponents.set(types, component);
  }

  onComponentRegister.forEach((callback) => callback(types, component));
};

Object.values(import.meta.glob('Draggables/**/*.{js,jsx,ts,tsx}')).forEach((module) =>
  module().then((module) => {
    const Component = module.default;
    registerDragComponent(Component.types, Component);
  }),
);

const subscribeToComponentRegister = (callback) => {
  onComponentRegister.push(callback);
  return () => {
    onComponentRegister.splice(onComponentRegister.indexOf(callback), 1);
  };
};

const ComponentRenderer = ({ item, itemType, ...props }) => {
  const Components = useSyncExternalStore(subscribeToComponentRegister, () => registeredComponents);

  const Component = Components.get(itemType);

  return Component && typeof Component === 'function' ? <Component data={item} type={itemType} {...props} /> : null;
};

const AppDragLayer = () => {
  return <CustomDragLayer>{(props) => <ComponentRenderer {...props} />}</CustomDragLayer>;
};

export default AppDragLayer;
