import { createInertiaApp, Head } from '@inertiajs/react';
import { mdiCheckCircle } from '@mdi/js';
import { Icon } from '@mdi/react';
import AccountMenu from 'Components/AccountMenu';
import DevModeListener from 'Components/DevMode/DevModeListener';
import DevModeOverlay from 'Components/DevMode/DevModeOverlay';
import * as React from 'react';
import { createElement, JSXElementConstructor, ReactElement, StrictMode, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import { toast, ToastContainer } from 'react-toastify';
import { FormErrorContextProvider, InitialApplicationContext } from 'Support/Contexts';
import 'Support/yupExtensions';
import { accountMenuItems, navItems } from '../MainLayoutConfig';
import AppDragLayer from 'Components/DragLayer/AppDragLayer';
import axios from 'axios';

type CreateInertiaAppProps = {
  analytics?: boolean;
  errorTracking?: boolean;
  progress?:
    | undefined
    | {
        delay?: number;
        color?: string;
        includeCSS?: boolean;
        showSpinner?: boolean;
      }
    | false;
};

type InitInertiaAppProps = {
  resolve: (name: string) => Promise<JSXElementConstructor<any>>;
  layout: JSXElementConstructor<any>;
} & CreateInertiaAppProps;

// export const initGoogleAnalytics = () => {
//   if (typeof window.gtag === 'function') {
//     let initialPageView = true;
//     Inertia.on('navigate', (event) => {
//       if (initialPageView) {
//         initialPageView = false;
//       } else {
//         window.gtag('event', 'page_view', {
//           'page_location': event.detail.page.url,
//         });
//       }
//     });
//   }
// };
//

export const initAxiosUnhandledPromiseRejectionHandler = () => {
  window.addEventListener('unhandledrejection', (event) => {
    // Handle cancellation
    if (axios.isCancel(event.reason)) {
      event.preventDefault();
      event.stopPropagation();
    }
  });
}

export const initErrorTracking = async () => {
  if (window.sentryDsn) {
    const Sentry = await import('@sentry/react');

    Sentry.init({
      dsn: window.sentryDsn,
      integrations: [new Sentry.BrowserTracing()],
      environment: window.sentryEnvironment,
      tracesSampleRate: window.sentryTracesSampleRate,
    });
  }
};

export const initInertiaApp = ({
  resolve: resolveComponent,
  layout: MainLayout,
  progress,
  errorTracking = true,
  analytics = true,
}: InitInertiaAppProps) => {

  initAxiosUnhandledPromiseRejectionHandler();
  if (errorTracking) {
    initErrorTracking();
  }
  //
  // if (analytics) {
  //   initGoogleAnalytics();
  // }

  return createInertiaApp<Window['inertiaInitialPageData']['props']>({
    progress: progress === undefined ? { showSpinner: true } : progress,
    page: window.inertiaInitialPageData,
    resolve(name) {
      return resolveComponent(name).catch((e) => {
        console.error(e);
        throw new Error(`The above error occurred when trying to load the page component for "${name}" and prevented it loading.`, {
          cause: e,
        });
      });
    },
    setup({ el, App, props }) {
      const initialApplication = { application: props.initialPage.props.application };

      createRoot(el).render(
        <StrictMode>
          <InitialApplicationContext.Provider value={initialApplication}>
            <App {...props}>
              {({ key, props, Component }) => {
                // @ts-ignore
                const componentLayout: (page: ReactElement, defaultLayout) => ReactElement | Array<(page: ReactElement) => ReactElement> =
                  Component.layout;
                const page = createElement(Component, { key, ...props });

                const defaultLayout = (children) => (
                  <MainLayout
                    children={children}
                    navItems={typeof navItems === 'function' ? navItems(page.props.application.user) : navItems}
                    largeLogoSrc="/logo-white.png"
                    smallLogoSrc="/logo-white.png"
                    accountMenu={<AccountMenu accountMenuItems={accountMenuItems} />}
                    site_name={page.props.application.site_name}
                    production={page.props.application.production}
                  />
                );

                useEffect(() => {
                  if (Array.isArray(page.props.flash)) {
                    page.props.flash.forEach(({ id, type, message, ...options }) => {
                      if (id) {
                        toast.update(id, {
                          autoClose: 5000,
                          delay: 20, // need to specify a delay here to avoid a bug https://github.com/fkhadra/react-toastify/issues/918
                          isLoading: false,
                          type,
                          icon: <Icon path={mdiCheckCircle} />,
                          render: message,
                          closeOnClick: true,
                          ...options,
                        });
                      } else {
                        toast(message, { type, ...options });
                      }
                    });
                  }
                }, [JSON.stringify(page.props.flash)]);
                let children;
                if (Array.isArray(componentLayout)) {
                  children = componentLayout
                    .concat(page)
                    .reverse()
                    .reduce((children, Layout) => createElement(Layout, { children }));
                } else if (typeof componentLayout === 'function') {
                  children = componentLayout(page, defaultLayout);
                } else {
                  children = defaultLayout(page);
                }

                return (
                  <DevModeListener>
                    <DndProvider backend={HTML5Backend}>
                      <FormErrorContextProvider>
                        <ToastContainer autoClose={5000} pauseOnFocusLoss pauseOnHover theme="colored" position="bottom-right" />
                        <Head key={page.props.page_title}>
                          <title>{(page.props.page_title ? `${page.props.page_title} | ` : '') + page.props.application.site_name}</title>
                        </Head>
                        {children}
                        <DevModeOverlay />
                        <AppDragLayer />
                      </FormErrorContextProvider>
                    </DndProvider>
                  </DevModeListener>
                );
              }}
            </App>
          </InitialApplicationContext.Provider>
        </StrictMode>,
      );
    },
  });
};
