import { mdiArrowLeftThin } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Trans, trans } from '@webfox/webfox-ui';
import cls from 'Support/cls';
import UserAvatarDraggable from 'Components/User/UserAvatarDraggable';
import ExampleAvatar from 'Images/example_avatar_jd.svg';
import React, { useRef, useState } from 'react';
import { useDrop } from 'react-dnd';

const DragAndDrop = () => {
  const [droppedUsers, setDroppedUsers] = useState([]);
  const hasDropped = useRef(false);

  const [{ isOver, isDragging }, drop] = useDrop(
    () => ({
      accept: ['user', 'example_user'],
      drop: (item) => {
        hasDropped.current = true;
        setDroppedUsers((droppedUsers) => [...droppedUsers, item]);
      },
      canDrop: (item) => !droppedUsers?.some((user) => user.id === item.id),
      collect: (monitor) => ({
        isOver: monitor.isOver() && monitor.canDrop(),
        isDragging: !!monitor.getItem() && monitor.canDrop(),
      }),
    }),
    [droppedUsers],
  );

  return (
    <div className="flex flex-col gap-2">
      <Trans
        as="pre"
        className="break flex flex-col gap-2 whitespace-pre-line font-sans"
        fallback="In most places throughout the app, you can drag user avatars.\n\nThis allows you to assign users to a job, or transcript simply by dragging the user's avatar onto it.\n\n"
      >
        help.lessons.dnd_assignment.content
      </Trans>
      <div className="flex flex-col rounded border bg-white p-2 shadow-sm">
        <div className="flex items-center gap-2">
          <UserAvatarDraggable user={{ name: 'John Doe', id: 0, avatar: ExampleAvatar }} itemType="example_user" subtitle="Translator" />
          {!isDragging && (
            <div className={cls('flex animate-pulse items-center gap-1 text-primary')}>
              <Icon path={mdiArrowLeftThin} className="w-4" />
              <Trans as="span" fallback="Drag Me!">
                help.lessons.dnd_assignment.actions.drag
              </Trans>
            </div>
          )}
        </div>
        <div
          ref={drop}
          className={cls(
            'relative flex flex-wrap items-center justify-center gap-1 overflow-hidden rounded bg-slate-100 duration-300',
            isDragging || droppedUsers?.length ? 'mt-2 h-20' : 'h-0',
          )}
        >
          {droppedUsers.map((user) => (
            <UserAvatarDraggable
              key={user.id}
              user={user}
              editable
              onRemove={() => setDroppedUsers((droppedUsers) => droppedUsers.filter((u) => u?.id !== user?.id))}
              itemType="example_user"
            />
          ))}
          <div
            className={cls(
              'pointer-events-none absolute inset-0 flex items-center justify-center bg-slate-300 duration-150',
              !isDragging && 'opacity-0',
              isOver ? 'bg-opacity-75' : 'bg-opacity,,,,-50',
            )}
          >
            <Trans as="span" className="text-gray-500" fallback="Drop Users Here">
              help.lessons.dnd_assignment.actions.drop
            </Trans>
          </div>
        </div>
      </div>
      <Trans
        as="div"
        fallback="Simple as that!, now you can drag and drop users to assign them to a job or transcript."
        className={cls('duration-300', !hasDropped.current && 'opacity-0')}
      >
        help.lessons.dnd_assignment.end_note
      </Trans>
    </div>
  );
};

DragAndDrop.title = trans('help.lesson.dnd_assignment.title', {}, 'Drag and Drop Assignment');
DragAndDrop.filter = (user) => user?.hasRole('admin');
export default DragAndDrop;
