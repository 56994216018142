import { motion, useMotionValue } from 'framer-motion';
import { useDragLayer } from 'react-dnd';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

export const CustomDragLayer = ({ children }) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const { itemType, isDragging, item } = useDragLayer((monitor) => {
    const offset = monitor.getSourceClientOffset();

    if (monitor.isDragging()) {
      x.set(offset?.x);
      y.set(offset?.y);
    }

    return {
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      isDragging: monitor.isDragging(),
    };
  });
  const renderItem = () => (typeof children === 'function' ? children({ item, itemType }) : children);

  if (!isDragging) return null;

  return (
    <div style={layerStyles}>
      <motion.div
        style={{ x, y }}
        transition={{
          duration: 0,
        }}
      >
        {renderItem()}
      </motion.div>
    </div>
  );
};
