import { format as formatDate, formatRelative as formatRelativeDate, parseISO as parseISODate } from 'date-fns';
import { enNZ } from 'date-fns/locale';
import { isString } from 'formik';

export const formattedDate = (value, format, fallbackValue) => (value ? formatDate( value instanceof Date ? value : parseISODate(value), format, { locale: enNZ }) : fallbackValue);

export const formatLaravelDate = (value, fallbackValue) => formattedDate(value, 'yyyy-MM-dd HH:mm:ss', fallbackValue);

export const formatShortDate = (value, fallbackValue) => formattedDate(value, 'dd MMM', fallbackValue);

export let formatTime = (value, fallbackValue) => formattedDate(value, 'hh:mm aa', fallbackValue);

export const formatShortDatetime = (value, fallbackValue) => formattedDate(value, 'hh:mm aa, dd MMM', fallbackValue);

export const formatLongDate = (value, fallbackValue) => formattedDate(value, 'dd MMM yyyy', fallbackValue);

export const formatLongDatetime = (value, fallbackValue) => formattedDate(value, 'hh:mm aa, dd MMMM yyyy', fallbackValue);

export const formatDateRange = (startDate, endDate, options = { dateStyle: 'medium', timeStyle: 'short' }) => {
  const startInst = startDate instanceof Date ? startDate : parseISODate(startDate);
  const endInst = endDate instanceof Date ? endDate : parseISODate(endDate);

  const formatter = new Intl.DateTimeFormat(undefined, options);
  return formatter.formatRange(startInst, endInst, false, false, false);
};

export const formatRelative = (value, fallbackValue) =>
  value ? formatRelativeDate(parseISODate(value), new Date(), { locale: enNZ }) : fallbackValue;

export const formatDateTimeInput = (value, fallbackValue) =>
  value ? formatDate(isString(value) ? parseISODate(value) : value, "yyyy-MM-dd'T'HH:mm", { locale: enNZ }) : fallbackValue;

export const formatNumber = (
  value,
  fallbackValue,
  formatterOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'decimal',
  },
) => {
  const numberFormatter = new Intl.NumberFormat(undefined, formatterOptions);
  return value === undefined || value === null ? fallbackValue : numberFormatter.format(value);
};

export const formatMonthsToPeriod = (value, fallback = 'Does Not Expire') => {
  if (!value) {
    return fallback;
  } else if (value === 1) {
    return '1 Month';
  } else if (value < 12) {
    return `${value} Months`;
  } else if (value === 12) {
    return '1 Year';
  } else {
    return `${value / 12} Years`;
  }
};

export const formatTitleCase = (value) =>
  value.replaceAll('_', ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

export const formatCurrency = (value, fallbackValue) =>
  formatNumber(value, fallbackValue, {
    currency: 'NZD',
    currencyDisplay: 'narrowSymbol',
    style: 'currency',
  });

export const formatCurrencyFull = (value, fallbackValue) =>
  formatNumber(value, fallbackValue, {
    currency: 'NZD',
    currencyDisplay: 'narrowSymbol',
    style: 'currency',
    maximumFractionDigits: 0,
  });
