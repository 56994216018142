import {Icon} from '@mdi/react';
import {motion} from 'framer-motion';
import React, {useState} from 'react';
import cls from 'Support/cls';

const SidebarButton = ({icon, tooltip, as: Element = 'button', asProps, ...props}) => {
  const [hovering, setHovering] = useState(false);

  return (
    <Element
      {...(Element === 'button' ? {type: 'button'} : {})}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className={cls(
        'group/sidebar-button relative isolate flex items-center justify-center rounded-xl bg-slate-100 duration-100 hover:bg-slate-200',
        props.className,
      )}
      {...props}
      {...asProps}
    >
      {tooltip && (
        <motion.div
          variants={{
            hidden: {
              width: 0,
            },
            visible: {
              width: 'auto',
              transition: {
                width: {
                  delay: 0.5,
                },
              },
            },
          }}
          transition={{
            backgroundColor: {
              duration: 0.1,
            },
          }}
          initial="hidden"
          animate={hovering ? 'visible' : 'hidden'}
          className="absolute inset-y-0 left-4 z-0 flex -translate-x-full items-center overflow-hidden whitespace-nowrap rounded-l-xl bg-inherit text-sm font-medium"
        >
          <span className="px-4">{tooltip}</span>
        </motion.div>
      )}
      <div className="relative z-10 rounded-xl bg-inherit p-2">{typeof icon === 'string' ?
        <Icon path={icon} className={cls('h-5 w-5', props.iconClasses)}/> : icon}</div>
    </Element>
  );
};

export default SidebarButton;
