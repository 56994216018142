import { motion } from 'framer-motion';
import React from 'react';

const SidebarPanel = ({ open, children }) => {
  return (
    <div className="w-full translate-x-full" inert={open ? undefined : ''}>
      <div className="w-0">
        <motion.div
          variants={{
            open: {
              height: 'auto',
            },
            closed: {
              height: 0,
            },
          }}
          initial="closed"
          animate={open ? 'open' : 'closed'}
          className="min-w-max -translate-x-full overflow-hidden rounded-lg bg-slate-200"
        >
          <div className="p-2">{children}</div>
        </motion.div>
      </div>
    </div>
  );
};

export default SidebarPanel;
