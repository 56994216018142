import cls from 'Support/cls';
import User from 'Components/User/User';
import React from 'react';

export const UserOption = ({ innerProps, data, isFocused }) => (
  <div {...innerProps} className={cls('cursor-pointer p-2', isFocused && 'bg-gray-100')}>
    <User user={data} />
  </div>
);

export default UserOption;
