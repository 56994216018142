import { Link } from '@inertiajs/react';
import { trans } from '@webfox/webfox-ui';
import React from 'react';
import route from 'ziggy-js';

const Ghosting = () => (
  <pre className="flex flex-col gap-2 whitespace-pre-line font-sans">
    {trans('help.lessons.ghosting.content', {
      link: (
        <Link href={route('users.index')} className="inline-block font-medium text-primary underline duration-100">
          Users
        </Link>
      ),
    })}
  </pre>
);

Ghosting.title = trans('help.lessons.ghosting.title');
Ghosting.filter = (user) => user?.hasRole('admin');

export default Ghosting;
